<script setup lang="ts">
import { useScroll, useIntersectionObserver } from '@vueuse/core'
const { y } = useScroll(window)

const props = defineProps({
  imageSrc: {
    type: String,
    default: null
  },
  videoSrc: {
    type: String,
    default: null
  },
  stroke: {
    type: String,
    default: null
  },
  fill: {
    type: String,
    default: null
  },
  animate: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    required: true
  }
})

const img = ref(null)
const el = ref(null)
const isVisible = ref(false)

useIntersectionObserver(el, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting
}, { threshold: 0.1, immediate: true })

const startY = ref(0)
watch(y, () => {
  if (img.value && isVisible.value && props.animate) {
    const translateY = Math.max(startY.value, startY.value + (y.value / window.innerHeight) * 30);
    img.value.style.transform = `translateY(-${translateY}%)`;
  }
})
</script>

<template>
  <div>
    <div v-if="props.videoSrc" ref="el" class="video">
      <video ref="img" playsinline autoplay muted loop >
        <source :src="props.videoSrc" type="video/webm">
      </video>
    </div>
    <svg v-else ref="el" width="100%" height="100%" viewBox="0 0 271 424" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern :id="props.id" patternUnits="userSpaceOnUse" width="100%" height="100%">
          <image
            v-if="props.imageSrc"
            ref="img"
            :href="props.imageSrc"
            x="-50%"
            y="0"
            height="120%"
          />
        </pattern>
      </defs>
      <path
        d="M256.645 92.2832L267.378 22.3353C267.945 19.1058 267.738 15.7761 266.774 12.7063C265.877 10.0206 264.279 7.74175 262.201 6.1707C259.906 4.48682 257.287 3.47675 254.563 3.24514C251.261 2.94455 247.924 3.31543 244.715 4.34828L80.9346 54.7325C78.3546 55.5556 75.883 56.828 73.6119 58.4999C71.1848 60.2631 68.9378 62.3522 66.9208 64.7095C64.8684 67.0753 63.0531 69.7012 61.5035 72.5376C60.0116 75.2427 58.8418 78.1579 58.0299 81.2258L4.80069 288.998C3.62902 293.558 2.96339 298.254 2.81182 302.986C2.65645 307.383 3.0156 311.767 3.88326 316.033C4.63541 319.857 5.97438 323.48 7.82432 326.731C9.46609 329.648 11.7546 331.995 14.4527 333.528L169.839 417.736C173.862 419.987 178.341 420.958 182.827 420.561C187.151 420.109 191.318 418.356 194.919 415.478C198.839 412.311 202.121 408.192 204.535 403.417C207.322 397.918 209.213 391.895 210.119 385.634L220.506 317.956L256.631 92.2915L256.645 92.2832Z"
        :fill="props.fill ? props.fill : `url(#${props.id})`"
        :stroke="props.stroke"
        stroke-width="4"
        stroke-dashoffset="2"
      />
    </svg>
  </div>
</template>

<style scoped>
.video {
  clip-path: path("M316.639 100.285L329.953 21.6873C330.656 18.0583 330.393 14.3176 329.187 10.8696C328.064 7.85312 326.066 5.2944 323.47 3.53141C320.604 1.64187 317.334 0.509701 313.933 0.252245C309.811 -0.0821248 305.646 0.337943 301.642 1.50161L97.2729 58.2747C94.0536 59.2021 90.9701 60.6342 88.1373 62.5148C85.11 64.4982 82.3078 66.8476 79.793 69.4982C77.2341 72.1583 74.9714 75.1103 73.0407 78.2987C71.1817 81.3393 69.7251 84.6158 68.7153 88.0634L2.52595 321.551C1.06898 326.676 0.243828 331.952 0.0603503 337.269C-0.128268 342.209 0.325328 347.135 1.4135 351.926C2.35695 356.222 4.03262 360.292 6.34564 363.943C8.39842 367.218 11.2578 369.853 14.6274 371.573L208.682 466.024C213.706 468.548 219.298 469.634 224.897 469.184C230.293 468.671 235.493 466.698 239.984 463.461C244.873 459.899 248.964 455.268 251.972 449.9C255.444 443.72 257.797 436.951 258.92 429.916L271.804 353.868L316.622 100.295L316.639 100.285Z");
  animation: fade-in 1s ease-in-out forwards;
  display: block;
  width: 385px;
  height: 470px;
  overflow: hidden;
  & video {
    height: 705px;
    transform: translateX(-10%);
    transform: translateY(-25%);
  }
}
svg {
  overflow: hidden;
  animation: fade-in 0.25s ease-in-out forwards;
}
path {
  /* fill: url(#img); */
  stroke-dasharray: 9000;
  stroke-dashoffset: 2000;
  animation: dash 1s ease-in-out forwards;
}

video {
  border-radius: 1rem;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@keyframes dash {
  from {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
   }
   to {
    stroke-dasharray: 9000;
    stroke-dashoffset: 2000;
   }
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
