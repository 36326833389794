<script setup lang="ts">
const props = defineProps({
  words: {
    type: Array as () => string[],
    required: true
  },
  firstLine: {
    type: Boolean,
    default: true
  }
})

const currentWordIndex = ref(0)

const changeWord = () => {
  currentWordIndex.value = (currentWordIndex.value + 1) % props.words.length
}

onMounted(() => {
  setInterval(changeWord, 3000)
})
</script>
<template>
  <article>
    <h1 class="title">
      <div>
        <slot class="top-title" name="title" />
        <span v-if="firstLine">
        <span
          v-for="(word, index) in words"
          :key="word"
          class="highlight"
          :class="{
            'active': index === currentWordIndex,
            'next': index === (currentWordIndex + 1) % words.length,
            'previous': index === (currentWordIndex - 1 + words.length) % words.length,
            'before-next': index === (currentWordIndex + 2) % words.length
          }"
        >
          &nbsp;{{ word }};
        </span>
        </span>
      </div>
      <slot class="bottom-title" name="titleContinued" />
      <span v-if="!firstLine">
      <span
        v-for="(word, index) in words"
        :key="word"
        class="highlight"
        :class="{
          'active': index === currentWordIndex,
          'next': index === (currentWordIndex + 1) % words.length,
          'previous': index === (currentWordIndex - 1 + words.length) % words.length,
          'before-next': index === (currentWordIndex + 2) % words.length
        }"
      >
        &nbsp;{{ word }};
      </span>
      </span>
    </h1>
    <slot name="button" />
    <p class="summary">
      <slot name="summary" />
    </p>
  </article>
</template>
<style lang="postcss" scoped>
@import "@/assets/css/typography.pcss";
@import "@/assets/css/shadow.pcss";
@import "@/assets/css/breakpoints.pcss";

.first-title {
  margin-left: -4rem;
}

article {
  position: relative;

  .pill {
    margin-top: 3rem;
    padding-bottom: 0.5rem;
    color: var(--color-rose-400);
  }

  .title {
    @mixin typeface-title;
    position: relative;
    width: 100%;
    margin: 0;
    margin-bottom: 0;

    > div {

      @mixin screen xs {
        transform: translateX(0);
      }
    }
  }
  span {
      color: var(--color-slate-400);
  }
  .highlight {
    position: absolute;
    transition: transform 1s, opacity 1s;
    opacity: 0;
    white-space: nowrap;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    &.previous {
      opacity: 0;
      transform: translateY(-25%);
    }

    &.next {
      opacity: 0.1;
      transform: translateY(100%);

    }
    &.before-next {
      opacity: 0;
      transform: translateY(200%);
    }
  }
  .summary {
    margin-top: 2rem;
    @mixin typeface-summary;
    color: var(--color-slate-400);
    width: 100%;
    max-width: 30rem;
  }
}
</style>
